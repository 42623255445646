import {
  IStep,
  EInputType,
  IVisibleCondition,
  EInputSize,
  ECatalogueID,
} from "../../../types";
import Yup from "../../../i18n/yup";
import { stringR, checkboxGroup, stringF } from "../../../helpers/validation";
import { countriesEU, regions, boolOptions } from "../common/options";
import api from "../../api";

const typeDetailValidation: any = Yup.mixed().when("activities__eu__type", {
  is: "LE (Libre Établissement)",
  then: stringR,
});
const typeDetailVisible: IVisibleCondition[] = [
  {
    name: "activities__eu__type",
    value: "LE (Libre Établissement)",
  },
];

const OTHER_VALUE = "14852";

const step5: IStep = {
  // ÉTAPE 5
  title: "Périmètre d’activité",
  description: "Détail sur la nature de votre activité",
  fields: [
    {
      ///// SECTION
      title: "Distribution IOBSP",
      fields: [
        {
          initialValue: "",
          label: "Type de clientèle majoritaire",
          required: true,
          name: "distribution__client_type_iobsp",
          validation: stringR,

          type: EInputType.Radio,
          options: () =>
            api.getCatalog(ECatalogueID.distribution__client_type_iobsp, true),
        },
        {
          initialValue: "",
          label: "Type de distribution majoritaire",
          required: true,
          name: "distribution__distribution_type_iobsp",
          type: EInputType.Radio,
          size: EInputSize.Large,
          validation: stringR,
          options: () =>
            api.getCatalog(
              ECatalogueID.distribution__distribution_type_iobsp,
              true
            ),
        },
        {
          initialValue: "",
          label: "Modalité de distribution",
          required: true,
          name: "distribution__modality_iobsp",
          type: EInputType.Radio,
          validation: stringR,
          options: () =>
            api.getCatalog(ECatalogueID.distribution__modality_iobsp, true),
        },
        {
          initialValue: "",
          label:
            "Pratiquez vous du démarchage téléphonique ? (appels non-sollicités)",
          required: true,
          name: "distribution__telemarketing_iobsp",
          type: EInputType.Radio,
          options: boolOptions,
          validation: stringR,
        },
      ],
    },

    {
      ///// SECTION
      title: "Produits IOBSP",
      fields: [
        {
          initialValue: "",
          label:
            "Produit représentant la part la plus importante du CA (un seul choix possible)",
          required: true,
          name: "distribution__main_ca_share_iobsp",
          type: EInputType.Radio,
          size: EInputSize.Large,
          options: () =>
            api.getCatalog(
              ECatalogueID.distribution__main_ca_share_iobsp,
              true
            ),
        },
        {
          initialValue: "",
          label:
            "Autre(s) produit(s) représentant la part la plus importante du CA",
          required: true,
          name: "distribution__main_ca_share_iobsp_other",
          placeholder: "",
          type: EInputType.Text,

          validation: Yup.mixed().when("distribution__main_ca_share_iobsp", {
            is: OTHER_VALUE,
            then: stringR,
          }),
          visible: [
            {
              name: "distribution__main_ca_share_iobsp",
              value: OTHER_VALUE,
            },
          ],
        },
        {
          initialValue: [],
          label: "Produits représentant plus de 10% du CA",
          required: true,
          name: "distribution__10_percent_ca_share_iobsp",
          type: EInputType.Checkbox,
          size: EInputSize.Large,
          options: () =>
            api.getCatalog(
              ECatalogueID.distribution__10_percent_ca_share_iobsp,
              true
            ),
          validation: checkboxGroup,
        },
        {
          initialValue: "",
          label: "Autre(s) produit(s) représentant + de 10% du CA",
          required: true,
          name: "distribution__10_percent_ca_share_iobsp_other",
          placeholder: "",
          type: EInputType.Text,
          validation: Yup.mixed().when(
            "distribution__10_percent_ca_share_iobsp",
            (values: string[]) => {
              return values && values.includes(OTHER_VALUE) ? stringR : stringF;
            }
          ),
          visible: [
            {
              name: "distribution__10_percent_ca_share_iobsp",
              value: OTHER_VALUE,
            },
          ],
        },
        {
          initialValue: "",
          label:
            "Nombre de partenariats actifs (ayant donné lieu à la souscription d'au moins un produit au cours de l'année)",
          required: true,
          name: "distribution__subscription_count_iobsp",
          type: EInputType.Radio,
          size: EInputSize.Large,
          options: () =>
            api.getCatalog(
              ECatalogueID.distribution__subscription_count_iobsp,
              true
            ),
          validation: stringR,
        },
      ],
    },
    {
      ///// SECTION
      title: "Périmètre d’activité",
      fields: [
        {
          initialValue: "",
          label: "Activité principale",
          required: true,
          name: "activities__is_main",
          type: EInputType.Radio,
          options: boolOptions,
          validation: stringR,
        },
        {
          initialValue: "",
          label: "Région d'implantation",
          required: true,
          name: "activities__region",
          placeholder: "Choisissez une région",
          type: EInputType.Select,
          options: regions,
          validation: stringR,
        },
      ],
    },
    {
      ///// SECTION
      title: "Passeport européen",
      fields: [
        {
          initialValue: "",
          label:
            "Exercez-vous votre activité d’intermédiation en assurance dans l’Espace Economique de l’Union Européenne ?",
          required: true,
          name: "activities__eu__inside_EU",
          type: EInputType.Radio,
          options: boolOptions,
          validation: Yup.string().required(),
        },
        {
          initialValue: "",
          label: "Régions dans lesquelles vous exercez votre activité",
          required: true,
          name: "activities__eu__country",
          placeholder: "Choisissez un pays",
          type: EInputType.Select,
          options: countriesEU,
          validation: Yup.mixed().when("activities__eu__inside_EU", {
            is: "Oui",
            then: stringR,
          }),
          visible: [
            {
              name: "activities__eu__inside_EU",
              value: "Oui",
            },
          ],
        },
        {
          initialValue: "",
          label: "",
          pdfLabel: "Type",
          name: "activities__eu__type",
          type: EInputType.Radio,
          options: [
            {
              label: "LPS (Libre Prestation de Service)",
              value: "LPS (Libre Prestation de Service)",
            },
            {
              label: "LE (Libre Établissement)",
              value: "LE (Libre Établissement)",
            },
          ],
          validation: Yup.mixed().when("activities__eu__inside_EU", {
            is: "Oui",
            then: stringR,
          }),
          visible: [
            {
              name: "activities__eu__inside_EU",
              value: "Oui",
            },
          ],
        },
        {
          initialValue: "",
          label: "Nom de la succursale",
          required: true,
          name: "activities__eu__branch__name",
          placeholder: "",
          type: EInputType.Text,
          validation: typeDetailValidation,
          visible: typeDetailVisible,
        },
        {
          initialValue: "",
          label: "Adresse de la succursale",
          required: true,
          name: "activities__eu__branch__address",
          placeholder: "",
          type: EInputType.Text,
          validation: typeDetailValidation,
          visible: typeDetailVisible,
        },
        {
          initialValue: "",
          label: "Nom du responsable de la succursale",
          required: true,
          name: "activities__eu__branch__responsable_name",
          placeholder: "",
          type: EInputType.Text,
          validation: typeDetailValidation,
          visible: typeDetailVisible,
        },
      ],
    },
  ],
};
export default step5;
