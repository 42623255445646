import { stringR } from "../../../helpers/validation";
import { EContainerVariant, EInputType, ETextVariant } from "../../../types";
import { boolOptions } from "./options";

export default function factoryGdprConsent() {
  return [
    {
      fieldsetTitle: "",
      containerVariant: EContainerVariant.Small,
      fields: [
        {
          variant: ETextVariant.Body1,
          content:
            "Les informations recueillies ci-dessous sont susceptibles d’être transmises à notre prestataire Andil, lequel est directement lié à notre plateforme de formation. Cette transmission a pour objectif de vous donner l’accès aux formations en partenariat avec la CNCEF. ",
        },
        {
          variant: ETextVariant.Body1,
          content:
            "Pour rappel, toute personne a la faculté d’exercer son droit d’accès aux données et de les faire rectifier en contactant : contact@cncef.org",
        },
        {
          variant: ETextVariant.Body1,
          content:
            "Les données recueillies ne peuvent en aucune façon être cédées ou louées, pas même à titre gratuit, à des tiers.",
        },
        {
          variant: ETextVariant.Body1,
          content:
            "Les données ne sont conservées que pour la durée nécessaire aux finalités pour lesquelles elles sont collectées et traitées. Ces dernières sont ainsi conservées tout au long de l’adhésion de la personne concernée à l’association et 5 ans après son retrait d’enregistrement auprès de l’association.",
        },
      ],
    },
    {
      initialValue: "",
      label: "J'autorise la transmission des informations",
      required: true,
      name: "gdpr_consent_for_employee",
      type: EInputType.Radio,
      options: boolOptions,
      validation: stringR,
    },
  ];
}
