import { IStep, ESectionVariant, EHumanType } from "../../../types";
import { factoryGdprConsent, factoryHuman } from "../common";

const step7: IStep = {
  // ÉTAPE 7
  title: "Organisation et moyen(s)",
  description:
    "Renseignements sur les moyens mis en place pour exercer l’activité d’IAS au sein de votre structure dont les salariés",
  fields: [
    {
      ///// SECTION
      title: "Salarié(s)",
      variant: ESectionVariant.NoPaddingBottom,
      fields: [
        ...factoryGdprConsent(),
        {
          repeatable: "employees_assurance",
          pdfLabel: "Salarié(e)",
          addButtonLabel: "Ajouter un(e) salarié(e)",
          initialEmpty: true,
          fields: factoryHuman(EHumanType.EmployeeAssurance),
        },
      ],
    },
  ],
};

export default step7;

// {
//   repeatable: "emails",
//   fields: [
//     {
//       initialValue: "",
//       label: "Email",
//       name: "association_email",
//       placeholder: "000000000",
//       type: EInputType.Email,
//       validation: Yup.string().email().required(),
//     },
//     {
//       initialValue: "",
//       label: "Description",
//       name: "description",
//       placeholder: "Je suis le placeholder",
//       type: EInputType.TextArea,
//       validation: stringR,
//     },
//   ],
// },
