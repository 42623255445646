import { IStep, ESectionVariant, EHumanType } from "../../../types";
import { factoryGdprConsent, factoryHuman } from "../common";

const step7: IStep = {
  // ÉTAPE 7
  title: "Organisation et moyen(s)",
  description:
    "Renseignements sur les moyens mis en place pour exercer l’activité d’IOBSP au sein de votre structure dont les salariés",
  fields: [
    {
      ///// SECTION
      title: "Salarié(s)",
      variant: ESectionVariant.NoPaddingBottom,
      fields: [
        ...factoryGdprConsent(),
        {
          repeatable: "employees_credit",
          pdfLabel: "Salarié(e)",
          addButtonLabel: "Ajouter un(e) salarié(e)",
          initialEmpty: true,
          fields: factoryHuman(EHumanType.EmployeeCredit),
        },
      ],
    },
  ],
};

export default step7;
