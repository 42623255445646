import {
  IStep,
  EInputType,
  EInputSize,
  ESectionVariant,
  EHumanType,
  ETextVariant,
  EContainerVariant,
} from "../../../types";
import Yup from "../../../i18n/yup";
import { stringR } from "../../../helpers/validation";
import {
  factoryHuman,
  factoryTable,
  factoryClientType,
  factoryGdprConsent,
} from "../common";

const step7: IStep = {
  // ÉTAPE 7
  title: "Organisation et moyen du conseiller en investissements financiers",
  description:
    "Renseignements sur les moyens mis en place pour exercer l’activité de CIF au sein de votre structure dont les salariés",
  fields: [
    {
      ///// SECTION
      title: "Clientèle",
      fields: [
        ...factoryClientType,
        {
          initialValue: "",
          label: "",
          required: true,
          name: "__test_client_type",
          type: EInputType.Hidden,
          size: EInputSize.Small,
          validation: Yup.mixed().when(
            [
              "client__non_professionnal",
              "client__professionnal",
              "client__eligible_counterparty",
            ],
            {
              is: (
                client__non_professionnal: any,
                client__professionnal: any,
                client__eligible_counterparty: any
              ) => {
                return !(
                  client__non_professionnal ||
                  client__professionnal ||
                  client__eligible_counterparty
                );
              },
              then: Yup.string().required(
                "Veuillez sélectionner au moins une catégorie de client"
              ),
            }
          ),
        },
      ],
    },
    {
      title: "Modalités d'approche des clients",
      fields: [
        {
          fieldsetTitle: "",
          containerVariant: EContainerVariant.Small,
          fields: [
            {
              variant: ETextVariant.Body1,
              content:
                "Si l’approche client est réalisée par des personnes extérieures à la société (démarcheurs de CIF…), préciser :",
            },
            {
              listItems: [
                "le statut de ces intermédiaires,",
                "le périmètre de leur mission,",
                "leur mode de rémunération.",
              ],
            },
            {
              variant: ETextVariant.Body1,
              content:
                "Le cas échéant, joindre un schéma du réseau de distribution accompagné d’une analyse de la conformité réglementaire de ce schéma lors de la dernière étape du programme d'activité.",
            },
          ],
        },
        {
          initialValue: "",
          label: "",
          pdfLabel:
            "Description des modalités d'approche des clients suivant leurs typologies",
          name: "client__typology_description",
          placeholder:
            "Description des modalités d'approche des clients suivant leurs typologies",
          type: EInputType.TextArea,
          validation: stringR,
        },
      ],
    },
    {
      ///// SECTION
      title: "Moyens humains",
      fields: [
        {
          fieldsetTitle: "",
          containerVariant: EContainerVariant.Small,
          fields: [
            {
              variant: ETextVariant.Body1,
              content: (
                <>
                  Présenter, sous forme d’un organigramme synthétique ou d’un
                  tableau, les moyens humains dont dispose(ra) le conseiller en
                  investissements financiers (à importer lors de la dernière
                  étape). Cet organigramme doit permettre de comprendre :
                </>
              ),
            },

            {
              listItems: [
                "les fonctions assurées par les dirigeants CIF;",
                <>
                  l’identification des{" "}
                  <strong>
                    salariés CIF<sup>1</sup>
                  </strong>
                  ,c’est-à-dire celles habilitées à fournir du conseil en
                  investissement,
                </>,
                "l’existence de différentes directions ou départements (CIF, courtage d’assurance, immobilier….)",
                "en cas de prestations de conseil en investissement indépendant et non indépendant, l’organisation retenue en vue de permettre la délivrance de ces 2 types de prestations.",
              ],
            },
            {
              variant: ETextVariant.Notice,
              content: (
                <>
                  <strong>
                    <sup>1</sup>Salariés CIF
                  </strong>{" "}
                  : personne employée pour exercer l’activité de conseil en
                  investissements financiers. En vertu de l’article 325-19 du
                  règlement général de l’AMF, le CIF doit s’assurer que le
                  salarié répond aux conditions de compétence professionnelle
                  prévues à l’article 325-1 et aux conditions d’honorabilité
                  prévues aux articles L.500-1 et D. 541-8 du code monétaire et
                  financier.
                </>
              ),
            },
          ],
        },
        {
          initialValue: "",
          label: "Fonctions assurées par les dirigeants",
          required: true,
          name: "hr__director_functions",
          placeholder: "Description des fonctions…",
          type: EInputType.Text,
          size: EInputSize.Large,
          validation: stringR,
        },
        {
          initialValue: "",
          label:
            "Directions ou départements existants (CIF, courtage d’assurance, immobilier…)",
          required: true,
          name: "hr__departements",
          placeholder: "Description des directions, départements…",
          type: EInputType.Text,
          size: EInputSize.Large,
          validation: stringR,
        },
        {
          initialValue: "",
          label: "Organisation retenue",
          name: "hr__organisation",
          placeholder:
            "Si indépendant et non-indépendant : organisation retenue en vue de permettre la délivrance de ces deux types de prestations.",
          type: EInputType.TextArea,
          visible: [
            {
              name: "activities__cif_activity",
              value: "Indépendant",
            },
            {
              name: "activities__cif_activity",
              value: "Non-indépendant",
            },
          ],
        },
      ],
    },
    {
      ///// SECTION
      title: "Salarié(s) CIF",
      variant: ESectionVariant.NoPaddingBottom,
      fields: [
        ...factoryGdprConsent(),
        {
          repeatable: "employees_cif",
          pdfLabel: "Salarié",
          initialEmpty: true,
          fields: factoryHuman(EHumanType.EmployeeCIF),
        },
      ],
    },
    {
      ///// SECTION
      title:
        "Organisation des activités de conseil en investissements financiers",
      fields: [
        {
          fieldsetTitle: "",
          containerVariant: EContainerVariant.Small,
          fields: [
            {
              variant: ETextVariant.Body1,
              content: (
                <>
                  Dans le cadre du déploiement des activités, indiquer les
                  procédures qui seront mises en œuvre (certaines procédures
                  peuvent être regroupées) :
                </>
              ),
            },
          ],
        },
        {
          title: "Document / Procédure",
          col1: "DISPONIBLE",
          col2: "À établir (uniquement pour les nouveaux adhérents)",
          fields: factoryTable(
            "activities_organisation",
            [
              { label: "Procédure d’entrée en relation" },
              { label: "Procédure cadre du parcours client, le cas échéant" },
              {
                label:
                  "Procédure de recueil des informations relatives à la connaissance du client",
              },
              { label: "Lettre de mission" },
              { label: "Déclaration d’adéquation" },
              { label: "Evaluation périodique de l’adéquation" },
              {
                label:
                  "Procédure RTO (convention, enregistrement et traçabilité des ordres passés)",
              },
              { label: "Procédure transparence des rémunérations" },
              {
                label:
                  "Procédure relative à l’information des clients (ex ante, ex post)",
              },
              { label: "Organisation du cabinet" },
              {
                label:
                  "Procédure liée à la coexistence de conseil en investissement indépendant/non indépendant, le cas échéant",
              },
              { label: "Processus Gouvernance des produits" },
              { label: "Procédure de gestion des conflits d’intérêts" },
              { label: "Procédure de traitement des réclamations des clients" },
              {
                label:
                  "Procédure de lutte contre le blanchiment et le financement du terrorisme – Cartographie des risques",
              },
              {
                label: "Procédure de sélection des fournisseurs et partenaires",
              },
              {
                label: "Procédure relative à la rémunération des salariés",
                tooltip:
                  "Requise pour les acteurs d’une importance significative",
              },
              {
                label: "Procédure relative à la conformité de la distribution",
                tooltip:
                  "Si recours à des partenaires distributeurs (démarcheurs de CIF).",
              },
              {
                label:
                  "Procédure relative à la confidentialité des informations",
              },
              {
                label:
                  "Procédure relative au Plan de continuité de l’activité (PCA)",
              },
            ],
            true
          ),
          values: ["disponible", "À établir"],
        },
      ],
    },
    {
      ///// SECTION
      title: "Outils et logiciels informatiques",
      fields: [
        {
          initialValue: "",
          label:
            "Renseigner les outils et logiciels informatiques utilisés pour la réalisation des activités de conseil en investissements financiers, en indiquant l’objet de leur utilisation.",
          placeholder:
            "Description des outils et logiciels informatiques utilisés",
          required: true,
          name: "hr__software_description",
          type: EInputType.TextArea,
          validation: stringR,
        },
      ],
    },
    {
      ///// SECTION
      title: "Conservation des données et continuité de l'activité",
      fields: [
        {
          initialValue: "",
          label:
            "Décrire brièvement l’organisation du conseiller en investissements financiers permettant de conserver tout document ou support fourni au client à l’occasion de la fourniture d’une prestation de conseil pendant toute la durée de la relation.",
          placeholder: "Description du plan de continuité d'activité",
          required: true,
          name: "hr__description_plan",
          type: EInputType.TextArea,
          validation: stringR,
        },
      ],
    },
  ],
};

export default step7;
