const baseURL = process.env.REACT_APP_DOCUMENT_URL + "/";
let documents = {
  attestationIOBSP: "20220916_Attestation_de_mandat_IOBSP.docx",
  attestationIOB: "attestation_honorabilite_IOB.docx",
  attestationIAS: "20242210_attestation_de_mandat_ias.docx",
  declarationHonneur: "declaration_honneur.docx",
  declarationHonneurCIF: "declaration_hono_CIF.doc",
  ficheRenseignement: "fiche-de-renseignements-rcp-gf.docx",
  listePersonnel: "liste_des_personnes_physiques.xlsx",
  listePersonnesPhysiques: "liste-personnes-physiques.xlsx",
  reglementInterieurAssurance: "reglement-interieur-cncef-assurance-2025.pdf",
  statutsAssurance: "statuts-cncef-assurance.pdf",
  declarationHonneurAssurance:
    "20221004-attestation-controle-honorabilite-employeur-IAS_VF.docx",
  declarationHonneurCredit:
    "20221004-attestation-controle-honorabilite-employeur-IOBSP_VF.docx",
  statutsCredit: "statuts-cncef-credit.pdf",
  reglementInterieurCredit: "reglement-interieur-cncef-credit-2025.pdf",
  reglementInterieurPatrimoine: "reglement-interieur-cncef-patrimoine-2025.pdf",
  statutsPatrimoine: "statuts-cncef-patrimoine-2024.pdf",
  bonneConduitePatrimoine: "code-de-bonne-conduite-cncef-patrimoine-2024.pdf",
  franceStatuts: "france-statuts.pdf",
  franceDeontologie: "france-deontologie.pdf",
  franceReglement: "france-reglement.pdf",
  conventionMediation: "procedure-de-mediation.pdf",
  noteConflitInteret: "note-conflit-d-interet.pdf",
  patrimoineExemple: "recapitulatif-demande-adhesion-cncef.pdf#toolbar=0",
  patrimoineAideCompletude: "2024-aide-completude-cncef-patrimoine.pdf",
};

Object.keys(documents).map(function (key) {
  documents[key] = baseURL + documents[key];
  return key;
});

export default documents;
